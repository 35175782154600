import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { pagesLinks } from "../../../../_V2/common/links"

export const cardList = [
	{
		url: pagesLinks.businessTypes.cafe.href,
		mediaContent: (
			<StaticImage
				src="./assets/automation-card-cafe.png"
				alt="Автоматизация кафе"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		text: "Кафе",
	},
	{
		url: pagesLinks.businessTypes.restaurant.href,
		mediaContent: (
			<StaticImage
				src="./assets/automation-card-restaurant.png"
				alt="Автоматизация ресторана"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		text: "Ресторан",
	},
	{
		url: pagesLinks.businessTypes.bar.href,
		mediaContent: (
			<StaticImage
				src="./assets/automation-card-bar.png"
				alt="Автоматизация бара"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		text: "Бар",
	},
	{
		url: pagesLinks.businessTypes.coffeehouse.href,
		mediaContent: (
			<StaticImage
				src="./assets/automation-card-coffee-house.png"
				alt="Автоматизация кофейни"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		text: "Кофейня",
	},
	{
		url: pagesLinks.businessTypes.foodtruck.href,
		mediaContent: (
			<StaticImage
				src="./assets/automation-card-foodtruck.png"
				alt="Автоматизация фудтрака"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		text: "Фудтрак",
	},
	{
		url: pagesLinks.businessTypes.hookah.href,
		mediaContent: (
			<StaticImage
				src="./assets/automation-card-hookah.png"
				alt="Автоматизация кальянной"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		text: "Кальянная",
	},
	{
		url: pagesLinks.businessTypes.fastfood.href,
		mediaContent: (
			<StaticImage
				src="./assets/automation-card-fastfood.png"
				alt="Автоматизация фастфуда"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		text: "Фастфуд",
	},
	{
		url: pagesLinks.businessTypes.cookery.href,
		mediaContent: (
			<StaticImage
				src="./assets/automation-card-cookery.png"
				alt="Автоматизация кулинарии"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		text: "Кулинария",
	},
	{
		url: pagesLinks.businessTypes.bakery.href,
		mediaContent: (
			<StaticImage
				src="./assets/automation-card-bakery.png"
				alt="Автоматизация пекарни"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		text: "Пекарня",
	},
	{
		url: pagesLinks.businessTypes.refectory.href,
		mediaContent: (
			<StaticImage
				src="./assets/automation-card-refectory.png"
				alt="Автоматизация столовой"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		text: "Столовая",
	},
	{
		url: pagesLinks.businessTypes.confectionery.href,
		mediaContent: (
			<StaticImage
				src="./assets/automation-card-confectionery.png"
				alt="Автоматизация кондитерской"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		text: "Кондитерская",
	},
	{
		url: pagesLinks.businessTypes.publicCatering.href,
		mediaContent: (
			<StaticImage
				src="./assets/automation-card-public-catering.png"
				alt="Автоматизация общепита"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		text: "Общепит",
	},
	{
		url: pagesLinks.businessTypes.pizzeria.href,
		mediaContent: (
			<StaticImage
				src="./assets/automation-card-pizzeria.png"
				alt="Автоматизация пиццерии"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		text: "Пиццерия",
	},
	{
		url: pagesLinks.businessTypes.pub.href,
		mediaContent: (
			<StaticImage
				src="./assets/automation-card-pub.png"
				alt="Автоматизация паба"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		text: "Паб",
	},
	{
		url: pagesLinks.businessTypes.sushi.href,
		mediaContent: (
			<StaticImage
				src="./assets/automation-card-sushi.png"
				alt="Автоматизация ресторана суши"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		text: "Ресторан суши",
	},
	{
		url: pagesLinks.businessTypes.darkKitchen.href,
		mediaContent: (
			<StaticImage
				src="./assets/automation-card-dark-kitchen.png"
				alt="Автоматизация дарк-китчена"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		text: "Дарк-китчен",
	},
	{
		url: pagesLinks.businessTypes.stadiums.href,
		mediaContent: (
			<StaticImage
				src="./assets/automation-card-stadiona.png"
				alt="Автоматизация массовых мероприятий"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		text: (
			<>
				Массовые<br/>мероприятия
			</>
		),
	},
]
