import React from "react"
import { Link } from "gatsby"

import styles from "./styles.module.scss"

interface Props {
	data: [
		{
			url: string
			mediaContent: JSX.Element
			text: string | JSX.Element
		}
	]
}

// Компонент список картинок с заголовком на ней
// Пример /automation/
export default function ListImageAndText(props: Props) {
	const data = props.data || []

	const onClick = (index: number) => {
		window.automationMenuIndex = index
	}

	return (
		<div className={styles.container}>
			<div className={styles.cardList}>
				{data.map((card, index) => (
					<Link
						to={card.url}
						className={styles.card}
						key={card.url}
						onClick={() => onClick(index)}
					>
						{card.mediaContent}
						<h2 className={styles.card__text}>{card.text}</h2>
					</Link>
				))}
			</div>
		</div>
	)
}
