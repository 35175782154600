import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../../layout/default'
import HeadingAndTextarea from '../../components/_V3/HeadingAndTextarea'
import ListImageAndText from '../../components/_V3/ListImageAndText'
import BusinessTypes from '../../components/_V2/BusinessTypes'

import pageStyles from '../../styles/_V2/pagesStyles/styles.module.scss'
import styles from '../styles/_V2/automation.module.scss'
import { headingAndTextarea } from '../../pages-data/_V3/automatization/index/headingAndTextarea'
import { cardList } from '../../pages-data/_V3/automatization/index/ListImageAndText/cardList'
import { businessData } from '../../pages-data/_V2/common/business-types-compact-white'
import { useLocalizationContext } from "../../localization/useLocalizationContext";

export default function PageAutomation() {
  const localizationContext = useLocalizationContext();

  const metaTags = [
    // {
    //   name: 'keywords',
    //   content: 'quick resto, автоматизация кафе, ресторанный бизнес, программа учёта, склад, общепит',
    // },
    {
      name: 'description',
      content: 'Система автоматизации ресторанного бизнеса любого формата Quick Resto ✔Онлайн-касса ✔Аналитика продаж и складской учет в облаке ✔Единая система обслуживания для Зала, Предзаказа и Доставки →Бесплатный пробный период 14 дней',
    },
    {
      property: 'og:url',
      content: 'https://quickresto.ru/automation/',
    },
    {
      property: 'og:title',
      content: 'Автоматизация ресторанного бизнеса любого формата | Quick Resto',
    },
    {
      property: 'og:description',
      content: 'Система автоматизации ресторанного бизнеса любого формата Quick Resto ✔Онлайн-касса ✔Аналитика продаж и складской учет в облаке ✔Единая система обслуживания для Зала, Предзаказа и Доставки →Бесплатный пробный период 14 дней',
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: 'https://quickresto.ru//static/pres-45aab977d36977d92eb637008a13bda5.png',
    },
  ]

  return (
    <DefaultLayout
      title="Автоматизация ресторанного бизнеса любого формата | Quick Resto"
      metaTags={metaTags}
      mainWrapperClassName={classNames(
			  pageStyles.pageWrapper,
			  pageStyles.pageGray,
			  pageStyles.automationPage,
      )}
      linkCanonical="https://quickresto.ru/automation/"
      footerClassName={styles.automation__footer}
    >
      <HeadingAndTextarea data={headingAndTextarea} />

      <ListImageAndText data={cardList} />

      <BusinessTypes
        key={localizationContext.locale}
        data={businessData(localizationContext.locale)}
        className={classNames(pageStyles.pageSection)}
      />
    </DefaultLayout>
  )
}
